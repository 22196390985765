import * as React from "react";
import Layout from "../components/layout";
import Banner from "../components/Banner";
import H1 from "../utils/H1";
import ExpandBox from "../components/ExpandBox/ExpandBox";
import { teamData } from "../data/team-data";
import { motion } from "framer-motion";
import Seo from "../components/seo";
import { partnersData } from "../data/partners-data";
const ApproachPage = () => {
  const members = teamData.data.team;
  const text =
    "Jan Sahas, Global Development Incubator and EdelGive Foundation are strategic partners that manage the governance and program delivery of MRC. The management is guided by a group of eminent steering council members and advisors.".split(
      " "
    );
  return (
    <Layout>
      <Seo title="Governance" url="https://migrantresilience.org/governance" />
      <div className="w-full flex flex-col justify-center items-center relative">
        <Banner src="/images/gov-banner.jpg" />
        {/* <div className="text-center md:w-10/12 px-4 md:px-0 mt-4 text-darkblue md:text-2xl text-xl font-bold">
          {text.map((el, i) => (
            <motion.span
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              transition={{
                duration: 0.25,
                delay: i / 10,
              }}
              key={i}
            >
              {el}{" "}
            </motion.span>
          ))}
        </div> */}
        <div className="w-full flex flex-row justify-center items-center relative z-0 ">
          <motion.h1
            initial={{ scale: 1.3, opacity: 0 }}
            whileInView={{ scale: 1, opacity: 100 }}
            viewport={{ once: true }}
            transition={{ duration: 0.8, delay: 0.1 }}
            className="text-center md:w-10/12 px-4 md:px-0 mt-4 bg-clip-text text-transparent bg-gradient-to-r from-darkblue to-blue md:text-2xl text-xl font-bold"
          >
            Jan Sahas, Global Development Incubator and EdelGive Foundation are
            strategic partners that manage the governance and program delivery
            of MRC. The management is guided by a group of eminent steering
            council members and advisors.
          </motion.h1>
          <img
            src="/images/dot-pattern.png"
            className="absolute top-0 md:left-20 left-0 w-80 object-contain opacity-80 -z-1"
          ></img>
        </div>

        <div className="w-full py-12 bg-sectionBlue mt-12 lg:px-28 md:px-12 px-4">
          <div className="w-full flex flex-col justify-end items-start relative z-0">
            <h2 className="uppercase text-left mb-8 text-darkblue md:text-4xl text-2xl transition-all duration-300 ease font-bold">
              Steering Council
            </h2>
          </div>
          <div className="grid lg:grid-cols-5 lg:grid-rows-2 md:grid-cols-3 grid-rows-auto grid-cols-2 4xl:gap12 gap-8">
            {members
              .filter((member) => member.category === "steering")
              ?.map((member) => (
                <ExpandBox data={member} />
              ))}
          </div>
        </div>
        {/* <div className="w-full pt-12 pb-6 bg-white lg:px-28 md:px-12 px-4">
          <div className="w-full flex flex-col justify-end items-start relative z-0">
            <h2 className="uppercase text-left mb-8 text-darkblue md:text-4xl text-2xl transition-all duration-300 ease font-bold">
              Our Advisors
            </h2>
          </div>
          <div className="grid lg:grid-cols-5 lg:grid-rows-2 md:grid-cols-3 grid-rows-auto grid-cols-2 4xl:gap12 gap-8">
            {members
              .filter((member) => member.category === "advisory")
              ?.map((member) => (
                <ExpandBox data={member} />
              ))}
          </div>
        </div> */}
        {/* <div className="w-full py-12 bg-sectionBlue lg:px-28 md:px-12 px-4 flex flex-col justify-center items-center">
                    <p className="text-gray text-md text-center">
                        <em>For information regarding support and partnerships, please write to Sneha
                            Lonkar on </em>
                        <a href="mailto:sneha.l@jansahasindia.org"
                            className="text-darkblue underline"><strong><em>sneha.l@jansahasindia.org</em></strong></a><strong><em><br />‍</em></strong><br /><em>For
                                news and media related information, please write to Sumit Singh on </em><a
                                    href="mailto:sumit@jansahasindia.org"
                                    className="text-darkblue underline"><strong><em>sumit@jansahasindia.org</em></strong></a><br />
                    </p>
                    <a
                        href="https://pages.razorpay.com/jansahasdonate" target="_blank" className="hover:shadow-xl shadow-darkblue hover:scale-105 transition-all ease duration-300 donate-button flex justify-center items-center mt-8 px-4 py-2 rounded-md bg-gradient-to-r from-darkblue/80 to-blue/90">
                        <img
                            src="/images/money2x.png" alt="donate icon" className="md:w-6 w-4 mr-4" />
                        <h2 className="text-white font-bold md:text-md text-base">Donate Now</h2>
                    </a>
                </div> */}
        <div className="w-full lg:pb-20 py-12 bg-white lg:px-28 md:px-12 px-4">
          <div className="w-full flex flex-col justify-center items-center relative z-0">
            <h2 className="text-left mb-8 text-darkblue md:text-4xl text-2xl transition-all duration-300 ease font-bold uppercase">
              Strategic Partners
            </h2>
          </div>
          {/* {
        link: "https://jansahas.org/",
        title: "Jan Sahas",
        icon: "/images/jansahas.png",
      }, */}
          <div className="grid md:grid-cols-3 grid-cols-1 w-full m-auto md:gap-12 gap-4 relative">
            {partnersData[0]?.partners?.map((row, key) => (
              <a
                href={row.link}
                key={"partner" + key}
                target="_blank"
                className="z-1 bg-white shadow-lg hover:shadow-2xl transition-all duration-300 ease border-darkblue border-1 rounded-xl px-8 w-full md:h-52 h-40 flex items-center justify-center"
              >
                <img
                  src={row.icon}
                  alt={row.title}
                  className="rounded-xl object-contain object-center h-[70%]"
                />
              </a>
            ))}

            <img
              src="/images/blue-dots.png"
              className="absolute md:top-32 top-16 right-40 w-80 object-contain opacity-80 -z-0"
            ></img>
          </div>
        </div>
      </div>
    </Layout>
  );
};
export default ApproachPage;
