export const teamData = {
  data: {
    team: [
      {
        id: 1,
        name: "Ms Anu Aga",
        title: "Former Chairperson, Thermax Limited",
        description:
          "Anu Aga is an Indian billionaire businesswoman and social worker who led Thermax, an energy and environment engineering business, as its chairperson from 1996 to 2004. She was awarded with the Mumbai Women of the Decade Achievers Award by ALL Ladies League, the all ladies wing of ASSOCHAM.",
        image: "/images/anu-aga.jpg",
        category: "steering",
        linkedIn: "",
      },
      {
        id: 2,
        name: "Ms Rati Forbes",
        title: "Director, Forbes Marshall Group",
        description: `Rati Forbes has been a Director of the Forbes Marshall Group since 1992; she was responsible for the Human Resources and Learning and Development function till 2011. During this time, Forbes Marshall was thrice recognised as one of the top 25 companies to work for in India, in the "Great Places to Work For" ranking - the highest placement being sixth. Today, Rati heads the Forbes Foundation and is responsible for CSR and Foundation activities across the company. She has been recognized for her social initiatives both within the company and in the wider world. She chaired the Confederation of Indian Industry’s western region cell for social development for a span of six years, the cell's main objective being to sensitize corporate entities to their social responsibilities.`,
        image: "/images/rati_new.jpg",
        category: "steering",
        linkedIn:
          "https://in.linkedin.com/in/rati-forbes-735b92231?trk=public_profile_browsemap",
      },
      {
        id: 3,
        name: "Ms Vidya Shah",
        title: "CEO, EdelGive Foundation",
        description:
          "Vidya Shah is the Executive Chairperson of EdelGive Foundation, the philanthropic initiative of Edelweiss Group, one of India's leading diversified financial services groups. With over 13 years of experience in philanthropy, she has developed a unique model for EdelGive's NGO partners, enabling them to scale their budgets, impact, and reach. Vidya serves on the boards of various prominent CSOs, including Akshaya Patra Foundation and Olympic Gold Quest.",
        image: "/images/Vidya-Shah.jpg",
        category: "steering",
        linkedIn:
          "https://www.linkedin.com/in/vidya-shah/?originalSubdomain=in",
      },
      {
        id: 4,
        name: "Mr Arun Maira",
        title: "Former Member, Planning Commission of India",
        description:
          "Arun Maira boasts a unique blend of experience as a pragmatic leader, advisor to leaders, and influential thinker on topics of leadership and institutional transformation across private, public, and social sectors. He has authored multiple books on institutional transformation and contributes regularly to journals. With a career spanning over three decades, he worked at the Tata Group for 25 years, followed by a decade with Arthur D. Little. He was Chairman of Boston Consulting Group, India until 2008 and a Member of India's Planning Commission from 2009-14. He is currently Chairman of Helpage International and an Advisor to civil society networks.",
        image: "/images/Arun-Maira.jpg",
        category: "steering",
        linkedIn: "https://www.linkedin.com/in/arun-maira-5499711b4",
      },
      {
        id: 5,
        name: "Mr Kumar Gera",
        title: "Chairman, Gera Developments Private Limited",
        description:
          "Kumar Gera is a highly accomplished civil engineer and real estate developer with over 40 years of experience. He currently serves as the Chairman of Gera Developments Pvt. Ltd, a leading real estate development company in Pune, Goa, Bangalore, and the US. With a career spanning several decades, Mr. Gera has held numerous leadership positions, including Director of the National Skill Development Corporation of India, Member of the State Planning Board of the Government of Maharashtra, and Founder National President of CREDAI. Additionally, he has been actively involved in various international organizations, including serving as the President of Junior Chamber International.",
        image: "/images/Kumar-Gera.jpg",
        category: "steering",
        linkedIn: "https://www.linkedin.com/in/kumargera",
      },
      // {
      //   id: 6,
      //   name: "Ms Lalita Vadia",
      //   title: "Former Migrant worker",
      //   description:
      //     "A former migrant worker, she and her family had always been on the move in search of seasonal work. In 2018, they migrated to Maharashtra under the guise of finding employment, only for her to be trafficked to Madhya Pradesh and sexually assaulted. Despite being a survivor herself, she is now a changemaker who works to empower other survivors of forced labor in her hometown. As a Dignity Fellow and member of the Survivors Forum at Jan Sahas, she fights for her own case and supports fellow migrant workers through Mazdoor Helpline. By helping them find their voice and establish self-help groups and community-based organizations, she ensures that they work with dignity.",
      //   image: "/images/lalita.jpg",
      //   category: "steering",
      //   linkedIn: "",
      // },
      {
        id: 7,
        name: "Mr Ashif Shaikh",
        title: "Co-Founder, Jan Sahas",
        description:
          "Ashif Shaikh is the co-founder of Jan Sahas, with over 20 years of experience in working towards safe migration, ending forced labor practices and violence against women. His work includes eradicating inhuman practices such as manual scavenging, caste-based commercial sexual exploitation of children and trafficking, as well as supporting survivors of rape, trafficking, and sexual abuse to access justice in India. Ashif is a member of various committees of NITI Aayog, Ministry of Social Justice and Empowerment, and other ministries of the Government of India. He has received various national and international awards and fellowships, including the Ashoka Fellowship, Times of India Social Impact Award, and Star Impact Award by Bill Clinton, the 42nd President of the United States. He serves on the boards of multiple national and international CSOs and networks.",
        image: "/images/Ashif-Shaikh3x.jpg",
        category: "steering",
        linkedIn: "",
      },
      {
        id: 8,
        name: "Mr. Vipul Roongta",
        title: "CEO & MD, HDFC Capital",
        description: `With over two decades of experience in the real estate industry, Vipul began his journey with retail mortgages and real estate transaction management, before progressing rapidly to wholesale credit, private equity and institutional framework creation for mass affordable housing standardisation and industrialisation. In 2016, he launched HDFC Capital Advisors, which has now become a mainstream platform for financing and technology intervention for mass affordable housing. HDFC Capital Advisors has raised the highest funds across the industry in its category, received recognition for "Remarkable Contribution to Affordable Housing" at CNBC-AWAAZ Real Estate Awards 2019, and is now one of the largest global platforms in its field.`,
        image: "/images/Vipul.jpg",
        category: "steering",
        linkedIn: "https://www.linkedin.com/in/vipul-roongta-44a42a2a/",
      },
      {
        id: 9,
        name: "Mr Shankar Venkateswaran",
        title: "ECube Investment Advisors Pvt. Ltd.",
        description:
          "Shankar Venkateswaran is a member of the founding team of ECube Investment Advisors, which is establishing a fund to invest in publicly listed companies and assist them in enhancing their sustainability/ESG performance. He also advises companies and NGOs on issues related to corporate sustainability and sustainable development. His previous full-time role was as the Chief of Tata Sustainability Group (TSG), which was responsible for providing guidance, thought leadership, and support on sustainability and corporate responsibility issues for the $100 billion Tata group of companies.",
        image: "/images/Shankar-Venkateswaran.jpg",
        category: "advisory",
        linkedIn:
          "https://www.linkedin.com/in/shankar-venkateswaran-06246115/?originalSubdomain=in",
      },
      {
        id: 10,
        name: "Ms Trishna Mohan",
        title: "Thomson Reuters Foundation",
        description:
          "Trishna joined the Thomson Reuters Foundation in 2018 as the Legal Programme Manager for South Asia. In her role, she manages Trust Law's network of NGOs, social enterprises, and lawyers in the region. She facilitates pro bono legal assistance for members and supports strategic legal research programs on regional and cross-border issues. Trishna has a keen interest in peacebuilding and business and human rights.",
        image: "/images/Trishna-Mohan.jpg",
        category: "advisory",
        linkedIn:
          "https://www.linkedin.com/in/trishna-kripalani-47614315/?originalSubdomain=in",
      },
      {
        id: 11,
        name: "Ms Svati Chakravarty Bhatkal",
        title: "Writer and Director",
        description:
          "Svati Chakravarty Bhatkal is a film-maker, documentarian, writer, lyricist, and researcher based in Mumbai. From 2012 to 2014, Bhatkal served as the Co-Director and Head of Field Research for three seasons of Satyamev Jayate (English: Truth Alone Triumphs), a television talk show hosted by actor Aamir Khan that addressed social issues in India. In its debut month of May 2012, it became the most talked-about show on social media worldwide.",
        image: "/images/Svati-Chakravarty-Bhatkal.jpg",
        category: "advisory",
        linkedIn:
          "https://www.linkedin.com/in/svati-chakravarty-14821a65/?originalSubdomain=in",
      },
      {
        id: 12,
        name: "Ms Ingrid Srinath",
        title: "Centre for Social Impact and Philanthropy",
        description:
          "Ingrid Srinath is a respected advocate for human rights, social justice, and civil society with over 25 years of experience. She has held leadership roles in prominent organizations, including as CEO of CRY, Secretary General at CIVICUS, and Executive Director of CHILDLINE India Foundation. Ingrid has also served on numerous advisory boards, including for the UNDP, WEF, and Oxford University's Young Lives project. She currently serves on the Governing Council of the BSE Social Stock Exchange and on several non-profit boards. In 2020, she was awarded the Distinguished Alumna Award from IIM Calcutta.",
        image: "/images/Ingrid-Srinath.jpg",
        category: "advisory",
        linkedIn:
          "https://www.linkedin.com/in/ingrid-srinath-355552/?originalSubdomain=in",
      },
      {
        id: 13,
        name: "Ms Gayatri Divecha",
        title: "Godrej Group",
        description:
          "Gayatri Divecha heads Corporate Social Responsibility for Godrej Industries and Associate Companies, driving the Group's sustainability agenda focused on skilling and entrepreneurship, prevention of vector-borne diseases, rural livelihoods, and access to entitlements. Gayatri has worked in the development sector across various roles and organizations, including academia, the private sector, and non-profits. Prior to Godrej, she was part of the Corporate Sustainability team at HSBC India, where she was responsible for driving the bank's portfolio of community investment programs.",
        image: "/images/Gayatri-Divecha.jpg",
        category: "advisory",
        linkedIn: "https://www.linkedin.com/in/gayatri-divecha-44856018/",
      },
      {
        id: 14,
        name: "Ms Suhasini Singh",
        title: "Country Manager for India, Fair Wear Foundation",
        description:
          "As a Country Manager for India with the Fair Wear Foundation (FWF) since 2013, Ms. Singh oversees Fair Wear's work and stakeholder engagement in India. Prior to FWF, she worked as Program Director at CIVIDEP, a labor rights organization based in Bangalore, India. Her work has primarily focused on labor rights issues in the garment industry, particularly the working and living conditions of women workers in the global supply chain. She has authored several articles and research reports on working conditions for garment workers and corporate accountability in the supply chain. Ms. Singh holds an MBA with specialization in Rural Development from the Institute of Rural Management, Jaipur. She enjoys reading, traveling, and music, and lives in Bangalore with her husband and two children.",
        image: "/images/Suhasini Singh.jpg",
        category: "advisory",
        linkedIn:
          "https://www.linkedin.com/in/suhasini-singh-7781176/?originalSubdomain=in",
      },
      {
        id: 15,
        name: "Ms Sonal Sachdev Patel",
        title: "CEO, GMSP Foundation",
        description:
          "She is the CEO of GMSP Foundation, a family charitable foundation established by Ramesh and Pratibha Sachdev, that supports frontline organizations working to improve the lives of some of the most vulnerable people in India and the UK. GMSP balances an evidence-based approach with patience and empathy, which leads to meaningful, longer-term relationships and measurable impact. This approach recognizes the rights, dignity, and happiness of the communities with whom they work.",
        image: "/images/Sonal%20Sachdev%20Patel.jpg",
        category: "advisory",
        linkedIn:
          "https://www.linkedin.com/in/sonal-sachdev-patel-666633/?originalSubdomain=uk",
      },
      {
        id: 16,
        name: "Ms Prachi Jain",
        title: "Head- HR, Macquarie, India",
        description:
          "With two decades of experience in HR & Talent Acquisition, She currently heads the Human Resource function for Macquarie in India. Prachi played a pivotal role in helping the organization grow. She has played a key role in the establishment of the Technology, Risk and MAM teams at Gurugram. A passionate D&I leader, she launched a returner program, women leadership programs, introduced & enhanced benefits for young mothers & same-sex partners in Macquarie.",
        image: "/images/Prachi-1080x675.jpg",
        category: "advisory",
        linkedIn:
          "https://www.linkedin.com/in/prachi-jain-she-her-a050859/?originalSubdomain=in",
      },
      {
        id: 17,
        name: "Mr Pradeep Nair",
        title: "Regional Director, Ford Foundation",
        description:
          "With over 25 years of global experience, in technology, management consulting and investing/ funding. He started in Silicon Valley as a big five management consultant, building software application products. After a stint with technology start-ups, he moved to New York, to be part of an advisory startup led by President Clinton and Mayor Bloomberg. This led to another early-stage opportunity to join an organization led by Paul Volcker, focused on the financial sector, academia and government.",
        image: "/images/Pradeep%20Nair.jpg",
        category: "steering",
        linkedIn: "https://www.linkedin.com/in/pradeep-nair-6704842/",
      },
      {
        id: 18,
        name: "Mr Rajendra Mewada",
        title: "Yuva Vikas Mandal",
        description:
          "Rajendra leads Yuva Vikas Mandal. Yuva Vikas Mandal works for social justice and equality in Sehore and other districts of Madhya Pradesh. It has interventions towards abolition of caste- based discrimination, and providing education and dignity to children belonging to excluded communities such as construction workers.",
        image: "/images/rajendra-mewada.jpeg",
        category: "advisory",
        linkedIn: "",
      },
      {
        id: 19,
        name: "Mr Srinivasan Iyer",
        title: "Ford Foundation",
        description:
          "Srinivasan Iyer is currently working as a Senior Program Officer in Ford Foundation New Delhi. Srinivasan holds a master’s of philosophy degree in economics from Jawaharlal Nehru University in New Delhi.",
        image: "/images/srinivasan.jpeg",
        category: "advisory",
        linkedIn: "",
      },
    ],
  },
};
